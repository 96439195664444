import React from 'react';
import Layout from "../layouts/pl";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';
import { FaCheck } from 'react-icons/fa';
import PhotoGallery from '../components/Gallery';

const Dziwnowek3 = (props) => {
  const data = useStaticQuery(graphql` 
  query {
    desktopImage: file(relativePath: { eq: "images/photos/amber3/2-main.jpg" }) {
          childImageSharp {
              fluid(quality: 100){
              ...GatsbyImageSharpFluid
              }
          }   
    }
    mobileImage: file(relativePath: { eq: "images/photos/amber3/2-mobile.jpg" }) {
      childImageSharp {
          fluid(quality: 50){
          ...GatsbyImageSharpFluid
          }
      }   
    }
  }`);
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];


  return (
    <Layout location={props.location}>
      <Fade up>
        <section className="area">
          <Img fluid={sources} alt="Dziwnowek apartamenty Amber" />
          <h1 className="title">APPARTEMENT DZIWNÓW</h1>
          <div className="content-container">

            <h1>Appartement Amber 3</h1>
            <p>Appartement Amber 3 in Dziwnów ist 61 m2 groß un befindet sich im zweiten Stock im Gebäude mit Schwimmbad. Appartment ist modern, komfortabel und im hohen Standard eingerichtet.</p>
            <p>Es hat ein Wohnzimmer mit einem Sofa(für 2 Personen), voll eingerichtete Küchenecke und einen Kamin. Es gibt hier zwei geschlossene Schlafzimmer mit Schränken, Ein Schlefzimmer hat ein Doppelbett und das ander ein bequemes Sofa für zwei Personen. Es gibt hier auch ein Badezimmer mit Dusche, einen Flur mit Schrank und Spiegeln und einen Balkon mit Gardenmöbel. Es hat auch einen Autoplatz MP15 in der Tiefgarage.</p>
            <p>Balkonblick (Seite - Schlafzimmer)auf den Fluss Dziwna und Marina mit vielen Segelbooten.</p>
            <p>Die Siedlung ist geschlossen und hat einen klainen Spielplatz für Kinder.</p>
            <p>Apparament ist für 6 Personen eingerichtet, zusätzlich bieten wir auch ein Kinderbett(ohne Bettzeug) und Kinderstuhl.</p>
            <p>Ein SPA-Bereich (Schwimmbad, Whirlpool, Saunen) und ein Fitnessraum sind im Aufenthaltspreis inbegriffen – keine Eintrittsbeschränkungen!</p>

            <h1>Ausrüstung:</h1>
            <ul>
              <li><FaCheck />Klimanlage</li>
              <li><FaCheck />Smart TV im Wohnzimmer</li>
              <li><FaCheck />2 x smart TV 32” in Schlafzimmern</li>
              <li><FaCheck />wi - fi </li>
              <li><FaCheck />der Kamin </li>
              <li><FaCheck />Induktionskochfeld (4 Plätze)</li>
              <li><FaCheck />Spülmaschine</li>
              <li><FaCheck />Kaffeemaschine</li>
              <li><FaCheck />elektrischer Wasserkocher</li>
              <li><FaCheck />Toaster</li>
              <li><FaCheck />Kühlschrank mit Gefrierfach</li>
              <li><FaCheck />Mikrowelle</li>
              <li><FaCheck />Besteck (Töpfe, Pfannen, Tassen, Gläser)</li>
              <li><FaCheck />Esstisch für 6 Personen</li>
              <li><FaCheck />Kaffeetisch</li>
              <li><FaCheck />Dusche, WC, Waschbecken</li>
              <li><FaCheck />Waschmaschine und Wäschetrockner</li>
              <li><FaCheck />Toilettenpapier, Seife, Tabletten für Waschmaschine, Spülmittel</li>
              <li><FaCheck />Fön</li>
              <li><FaCheck />Badetücher</li>
              <li><FaCheck />Staubsauger</li>
              <li><FaCheck />Ausziehsofa im Wohnzimmer</li>
              <li><FaCheck />Doppelbett</li>
              <li><FaCheck />Spielzeug für Kinder</li>
              <li><FaCheck />großer Schrank</li>
              <li><FaCheck />im Flur Schrank mit Spiegeln</li>
              <li><FaCheck />Bügeleisen und Bügelbrett</li>
              <li><FaCheck />Windschutz, Sonnenzelt</li>
            </ul>

            <p style={{ marginTop: 20 }}>Im Appartement nr 3 akzeptieren wir <b>keine Tiere</b>.</p>

          </div>

        </section>
      </Fade>


      <Fade up>
        <section className="photos" id="section3">
          <h1>Galeria</h1>
          <div className="photos">
            <PhotoGallery galleryType='apartament3' />
          </div>
        </section>
      </Fade>

    </Layout>
  )
}



export default Dziwnowek3

